

























































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import gsap from 'gsap'
import { motionOK } from '@/inc/utils/motionOK'

export default defineComponent({
  name: 'arrow-link',
  props: {
    url: {
      // Could be anything that works with -> https://router.vuejs.org/api/#to
      required: false,
    },
    label: {
      required: false,
      type: String,
    },
    target: {
      required: false,
      type: String,
    },
    animatedEntrance: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  setup(props) {
    const rootRef = ref()

    onMounted(() => {
      if (props.animatedEntrance && motionOK()) {
        gsap.set(rootRef.value.$el, {
          opacity: 0,
          x: -40,
        })
      }
    })

    const getTween = () =>
      gsap.to(rootRef.value.$el, {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: 'power4.out',
      })

    return {
      rootRef,
      getTween,
    }
  },
})
