<template>
  <div>
    <div class="pt-header mb-xxxl">
      <div class="row">
        <div class="col-xxs-offset-2 col-xxs-20 col-m-13">
          <LineSplitter
            v-if="content.headline"
            :content="content.headline"
            class="page-title intro__title"
            :type="'h1'"
            ref="titleRef"
          />

          <LineSplitter
            v-if="content.subtitle"
            :content="content.subtitle"
            class="page-subtitle intro__subtitle alt-heading mb-xxl"
            ref="subtitleRef"
            :type="'h2'"
          />
        </div>
      </div>
    </div>

    <div class="mb-xxl">
      <div class="row">
        <div class="col-xxs-20 col-xxs-offset-2 col-l-offset-6 col-l-16">
          <ul class="news-listing">
            <li
              class="news-listing__item col-xxs-24 col-s-8"
              v-for="(news, i) in paginatedNews"
              :key="i"
            >
              <NewsCard :news="news" ref="newsRef" />
            </li>
          </ul>

          <div
            class="load-more-container"
            v-if="paginatedNews && paginatedNews.length > 0"
          >
            <Curtain
              class="button--has-curtain--light load-more"
              :label="content.loadMore"
              v-if="remainingNewsCount > 0"
              :number="remainingNewsCount"
              type="button"
              @click.native="loadMore"
            />
          </div>
        </div>
      </div>
    </div>

    <FeaturedReferences
      v-if="content.references"
      :content="content.references"
    />

    <BigCTA v-if="!Array.isArray(content.cta)" :cta="content.cta" />
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import FeaturedReferences from '@/components/FeaturedReferences.vue'
import BigCTA from '@/components/BigCTA.vue'
import NewsCard from '@/components/NewsCard.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'
import LineSplitter from '@/components/animation/LineSplitter.vue'

const PER_PAGE = 12

export default defineComponent({
  components: {
    BigCTA,
    NewsCard,
    FeaturedReferences,
    LineSplitter,
  },
  setup() {
    const { content } = useGetters(['content'])

    const currentIndex = ref(PER_PAGE)
    const newsCount = computed(() => content.value.news.length)
    const remainingNewsCount = computed(() =>
      Math.max(0, content.value.news.length - currentIndex.value)
    )

    const sector = ref('')
    const solution = ref('')
    const titleRef = ref()
    const subtitleRef = ref()

    const paginatedNews = computed(() =>
      content.value.news.slice(0, currentIndex.value)
    )

    const loadMore = () => {
      const prevIndex = currentIndex.value

      if (remainingNewsCount.value <= PER_PAGE) {
        currentIndex.value = content.value.news.length
      } else {
        currentIndex.value += PER_PAGE
      }

      // Importing nextTick from @vue/composition-api does not seem to work.
      // setTimeout does the trick.
      window.setTimeout(() => {
        // Compute the count of newly loaded news
        const countDiff = currentIndex.value - prevIndex
        const recentlyAddedNews = newsRef.value.slice(-countDiff)

        // Run the intro tween for the newly loaded news only
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })
        recentlyAddedNews.forEach((news, i) => {
          TL.add(news.getTween(), `0+=${0.1 * i}`)
        })
      }, 1)
    }

    const newsRef = ref()
    const animateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), 'start')
        }

        if (subtitleRef.value) {
          TL.add(subtitleRef.value.getTween(), 'start+=0.1')
        }

        newsRef.value.forEach((news, i) => {
          TL.add(news.getTween(), `start+=${0.1 * i}`)
        })
      }
    }

    onMounted(() => {
      animateIn()
    })

    return {
      content,
      sector,
      solution,
      currentIndex,
      newsCount,
      remainingNewsCount,
      paginatedNews,
      loadMore,
      newsRef,
      titleRef,
      subtitleRef,
    }
  },
})
</script>
