



































































































































































import { defineComponent, ref } from '@vue/composition-api'
import WebP from '@/components/WebP.vue'
import ArrowLink from '@/components/ArrowLink.vue'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

export default defineComponent({
  name: 'featured-references',
  props: {
    content: {
      required: true,
      type: Object,
    },
  },
  components: { WebP, LineSplitter, ArrowLink },
  setup() {
    const baselineRef = ref()
    const titleRef = ref()
    const referencesRef = ref()
    const referenceTitlesRef = ref()
    const referenceDetailsRef = ref()
    const ctaRef = ref()

    const formatRefDetails = ref =>
      [ref.location, ref.surface].filter(val => val).join(', ')

    const animateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (baselineRef.value) {
          TL.add(baselineRef.value.getTween(), 'start')
        }

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), 'start+=0.25')
        }

        referencesRef.value.forEach((ref, i) => {
          const nestedTL = gsap.timeline({
            defaults: {
              duration: 1,
              ease: 'power4.out',
            },
          })

          const domEl = ref._isVue ? ref.$el : ref
          nestedTL.to(
            domEl,
            {
              '--progress': 1,
              // eslint-disable-next-line quote-props
              duration: 2,
            },
            'start'
          )

          nestedTL.add(referenceTitlesRef.value[i].getTween(), 'start+=0.2')
          nestedTL.add(referenceDetailsRef.value[i].getTween(), 'start+=0.4')

          TL.add(nestedTL, i === 0 ? '-=1' : '-=1.5')
        })

        if (ctaRef.value) {
          TL.add(ctaRef.value.getTween(), '-=0.7')
        }
      }
    }

    const mouseEnterReference = i => {
      const reference = referencesRef.value[i]._isVue
        ? referencesRef.value[i].$el
        : referencesRef.value[i]
      const img = reference.querySelector('img')

      gsap.killTweensOf(img)
      gsap.fromTo(
        img,
        {
          clipPath: 'inset(0% 100% 0% 0%)',
          x: -40,
        },
        {
          clipPath: 'inset(0% 0% 0% 0%)',
          duration: 0.5,
          x: 0,
          ease: 'power4.out',
        }
      )
    }

    const mouseLeaveReference = i => {
      const reference = referencesRef.value[i]._isVue
        ? referencesRef.value[i].$el
        : referencesRef.value[i]
      const img = reference.querySelector('img')
      gsap.killTweensOf(img)
      gsap.to(img, {
        clipPath: 'inset(0% 0% 0% 100%)',
        duration: 0.5,
        x: 40,
        ease: 'power4.out',
      })
    }

    return {
      formatRefDetails,
      animateIn,
      baselineRef,
      titleRef,
      referencesRef,
      referenceTitlesRef,
      referenceDetailsRef,
      ctaRef,
      mouseEnterReference,
      mouseLeaveReference,
    }
  },
})
